import { render, staticRenderFns } from "./I2CSchema.vue?vue&type=template&id=5ea2a998&"
import script from "./I2CSchema.vue?vue&type=script&lang=js&"
export * from "./I2CSchema.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template_fvutn5opv3sndrfwleawncymkq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jrh/work/aardvark/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ea2a998')) {
      api.createRecord('5ea2a998', component.options)
    } else {
      api.reload('5ea2a998', component.options)
    }
    module.hot.accept("./I2CSchema.vue?vue&type=template&id=5ea2a998&", function () {
      api.rerender('5ea2a998', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/i2c/modules/I2CSchema.vue"
export default component.exports